.gradient-text {
  background: linear-gradient(90deg, #56CCF2 -7.25%, #C6C7FF 24.16%, #E1A9F6 55.53%, #F9ABE7 108.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* bg="#292929"
border="2px solid #383838" */
/* .redemption-select select:invalid,
.redemption-select select option[value=""] {
  background: #292929;
  border: 2px solid #383838;
  color: rgba(85, 85, 85, 1) !Important;
}


.redemption-select select,
.redemption-select select option {
  background: #292929;
  border: 2px solid #383838;
  color: white !Important;
}

[hidden] {
  display: none;
} */