#snap-select select:invalid,
#snap-select select option[value=""] {
  border-radius: 10px;
  background: #292929;
  border: 2px solid #383838;
  color: rgba(85, 85, 85, 1) !Important;
}


#snap-select select,
#snap-select select option {
  border-radius: 10px;
  background: #292929;
  border: 2px solid #383838;
  color: white !Important;
}

#snap-select-locked select,
#snap-select-locked select option {
  border-radius: 10px;
  background: #292929;
  border: 2px solid #383838;
  color: rgba(85, 85, 85, 1) !Important;
}

[hidden] {
  display: none;
}

.pulse {
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

.green {
  background: rgba(32, 224, 112);
}

.yellow {
  background: #E0C120;
}

.red {
  background: #E24E51;
}

.pulse-anim {
  background: rgba(32, 224, 112);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  box-shadow: 0 0 0 0 rgba(32, 224, 112, 1);
  transform: scale(1);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(32, 224, 112, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(32, 224, 112, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(32, 224, 112, 0);
  }
}