input[type="file"] {
  display: none;
}

.custom-file-upload {
  /* border: 1px solid #ccc; */
  display: inline-block;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}