.redemption-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

redemption-content .redemption-container>img {
  width: 100%;
  height: 360px;
}

.redemption-content {
  width: 942px;
}

.redemption-content>h2 {
  font-weight: 800;
  font-size: 30px;
  color: white;
  margin: 80px 0;
}