.redemption-card {
  width: 100%;
  flex-direction: column;
  max-width: 340px;
  height: 340px;
  position: relative;
  background: url('../../assets/imgs/circle2.png');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: 120% 100%; */
  /* background-position: -50px -50px; */
  background-repeat: repeat-x;
  /* animation: animatedBackground 10s linear infinite alternate; */
  transition: all .1s ease-in-out;
  border: 4px solid #383838;
  border-radius: 35px;
  overflow: hidden;
}

@keyframes animatedBackground {
  from {
    background-position: -50px -50px;
  }

  to {
    background-position: 50px 0;
  }
}

.redemption-card:hover {
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.5), 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: scale(1.02);
  /* border: 4px solid #ccc; */
}

.redemption-card-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 365px;
  height: 365px;
}

.redemption-card-content {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 20px;
  flex-direction: column;
  z-index: 100;
  /* backdrop-filter: blur(10px); */
  /* border-radius: 30px; */
  transition: all .5s ease-in-out;
  background-color: rgba(50, 50, 50, 1);
}

.redemption-card-content:hover {
  background-color: rgba(50, 50, 50, .25);
}

.redemption-card-btn {
  display: flex;
  height: 55%;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.redemption-card-btn>p {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  display: inline-block;
  border: 2px solid #FFFFFF;
  padding: 7px 15px;
  border-radius: 35px;
  transition: opacity .5s ease-in-out;
  color: white;
}

.redemption-card-tittle {
  font-weight: 800;
  font-size: 20px;
  background: -webkit-linear-gradient(45deg,
      #CFA4FD 2.35%,
      #BE80FA 31.77%,
      #7834B3 90.99%,
      #7834B3 102.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.redemption-card-description {
  margin: 20px 0;
  color: #DADADA;
  font-size: 14px;
}

.redemption-card-arrow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}